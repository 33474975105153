/* eslint-disable no-console */
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { Config, CONFIG_TOKEN } from '../../wtax-config';
import { nthSegment, parseToken } from './functions';
import { AccountMockService } from './services/account-mock.service';
import { AttachmentsMockService } from './services/attachments-mock.service';
import { ClientManagementMockService } from './services/client-management-mock.service';
import { DiscretionManagementMockService } from './services/discretion-management-mock.service';
import { DocumentTypesMockService } from './services/document-types-mock.service';
import { DocumentsMockService } from './services/documents-mock.service';
import { FormPopulationMockService } from './services/form-population-mock.service';
import { MessagesMockService } from './services/messages-mock.service';
import { PaymentsMockService } from './services/payments-mock.service';
import { ProfileMockService } from './services/profile-mock.service';
import { QuestionsMockService } from './services/questions-mock.service';
import { TaxRatesMockService } from './services/tax-rates-mock.service';
import { UsersMockService } from './services/users-mock.service';

const MOCK_RESPONSE_DELAY_MS = 1000;
const NOT_MOCKED_RESPONSE = new HttpResponse({
  status: 500,
  body: '[NOT MOCKED] not mocked',
});

const LOG_TAG_STYLE = [`background: #3498db`, `border-radius: 0.5em`, `color: white`, `font-weight: bold`, `padding: 2px 0.5em`].join(';');
const LOG_ERROR_TAG_STYLE = [`background: #c0392b`, `border-radius: 0.5em`, `color: white`, `font-weight: bold`, `padding: 2px 0.5em`].join(
  ';'
);
const LOG_TAG = 'MOCK';

const logGroup = (label: string) => console.groupCollapsed(`%c${LOG_TAG}`, LOG_TAG_STYLE, label);
const log = (...args: any) => console.log(`%c${LOG_TAG}`, LOG_TAG_STYLE, ...args);
const logGroupEnd = () => console.groupEnd();
const logError = (...args: any) => console.error(`%c${LOG_TAG}`, LOG_ERROR_TAG_STYLE, ...args);

@Injectable()
export class MockInterceptor implements HttpInterceptor {
  private readonly profileMockService: ProfileMockService;
  private readonly attachmentsMockService: AttachmentsMockService;
  private readonly documentsMockService: DocumentsMockService;
  private readonly accountMockService: AccountMockService;
  private readonly usersMockService: UsersMockService;
  private readonly paymentsMockService: PaymentsMockService;
  private readonly clientManagementMockService: ClientManagementMockService;
  private readonly discretionManagementMockService: DiscretionManagementMockService;
  private readonly documentTypesMockService: DocumentTypesMockService;
  private readonly messagesMockService: MessagesMockService;
  private readonly questionsMockService: QuestionsMockService;
  private readonly formPopulationMockService: FormPopulationMockService;
  private readonly taxRatesMockService: TaxRatesMockService;

  constructor(@Inject(CONFIG_TOKEN) private readonly config: Config) {
    this.attachmentsMockService = new AttachmentsMockService();
    this.documentsMockService = new DocumentsMockService();
    this.accountMockService = new AccountMockService();
    this.usersMockService = new UsersMockService();
    this.clientManagementMockService = new ClientManagementMockService(this.attachmentsMockService);
    this.discretionManagementMockService = new DiscretionManagementMockService(this.attachmentsMockService);
    this.paymentsMockService = new PaymentsMockService(this.clientManagementMockService);
    this.profileMockService = new ProfileMockService(this.accountMockService);
    this.formPopulationMockService = new FormPopulationMockService();
    this.documentTypesMockService = new DocumentTypesMockService(this.attachmentsMockService);
    this.messagesMockService = new MessagesMockService(
      this.profileMockService,
      this.attachmentsMockService,
      this.clientManagementMockService
    );
    this.questionsMockService = new QuestionsMockService();
    this.taxRatesMockService = new TaxRatesMockService();
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.config.apiMocking) {
      return next.handle(request);
    }

    if (!request.url.startsWith(this.config.apiBaseUrl)) {
      return next.handle(request);
    }
    console.log(`intercepting request ${request.method} ${request.urlWithParams} | token: ${parseToken(request)}`);
    logGroup(`intercepting request ${request.method} ${request.urlWithParams} | token: ${parseToken(request)}`);

    const method = request.method;
    const path = request.url.substring(this.config.apiBaseUrl.length);

    let response: HttpResponse<any>;

    // default leave it as NOT_MOCKED
    response = NOT_MOCKED_RESPONSE;

    // profile
    if (method === 'GET' && path === '/profile') {
      response = this.profileMockService.getUserProfile(request);
    }
    // attachments
    else if (method === 'POST' && path === '/attachments') {
      response = this.attachmentsMockService.uploadAttachment(request);
    } else if (method === 'POST' && path === '/attachments/base64') {
      response = this.attachmentsMockService.uploadBase64Attachment(request);
    } else if (method === 'GET' && path === '/attachments/bulk') {
      response = this.attachmentsMockService.getAttachmentsByIds(request);
    } else if (method === 'GET' && path.startsWith('/attachments')) {
      response = this.attachmentsMockService.getAttachment(request);
    }
    // documents
    else if (method === 'PUT' && path.startsWith('/documents') && path.endsWith('/sign')) {
      const id = nthSegment(path, 2);

      response = this.documentsMockService.signDocument(request, id);
    } else if (method === 'PUT' && path.startsWith('/documents') && path.endsWith('/mark-printed')) {
      const id = nthSegment(path, 2);

      response = this.documentsMockService.markDocumentAsPrinted(request, id);
    } else if (method === 'POST' && path.startsWith('/documents') && path.endsWith('/upload')) {
      const id = nthSegment(path, 2);

      response = this.documentsMockService.uploadDocument(request, id);
    }
    // account
    else if (method === 'GET' && path === '/account/status') {
      response = this.accountMockService.getStatus(request);
    } else if (method === 'GET' && path === '/account/company-details') {
      response = this.accountMockService.getCompanyDetails(request);
    } else if (method === 'PUT' && path === '/account/company-details') {
      response = this.accountMockService.updateCompanyDetails(request);
    } else if (method === 'GET' && path === '/account/custodian-details') {
      response = this.accountMockService.getCustodianDetails(request);
    } else if (method === 'PUT' && path === '/account/custodian-details') {
      response = this.accountMockService.updateCustodianDetails(request);
    } else if (method === 'GET' && path === '/account/discretion-details') {
      response = this.accountMockService.getDiscretionDetails(request);
    } else if (method === 'PUT' && path === '/account/discretion-details') {
      response = this.accountMockService.updateDiscretionDetails(request);
    } else if (method === 'GET' && path === '/account/banking-details') {
      response = this.accountMockService.getBankingDetails(request);
    } else if (method === 'PUT' && path === '/account/banking-details') {
      response = this.accountMockService.updateBankingDetails(request);
    } else if (method === 'GET' && path === '/account/authorized-signatories') {
      response = this.accountMockService.getAuthorizedSignatories(request);
    } else if (method === 'PUT' && path === '/account/authorized-signatories') {
      response = this.accountMockService.updateAuthorizedSignatories(request);
    }
    // users
    else if (method === 'GET' && path === '/users') {
      response = this.usersMockService.getUsers(request);
    } else if (method === 'POST' && path === '/users/bulk') {
      response = this.usersMockService.createUsers(request);
    }
    // clients
    else if (method === 'GET' && path === '/refund-analysis/status') {
      response = this.clientManagementMockService.getRefundAnalysisStatus(request);
    } else if (method === 'GET' && path === '/clients/metadata') {
      response = this.clientManagementMockService.getClientsMetadata(request);
    } else if (method === 'GET' && path === '/clients') {
      response = this.clientManagementMockService.getClients(request);
    } else if (method === 'GET' && path === '/questions/clients') {
      response = this.clientManagementMockService.getClientsForQuestions(request);
    } else if (method === 'GET' && path === '/clients/information-sheet') {
      response = this.clientManagementMockService.getClientsInformationSheet(request);
    } else if (method === 'POST' && path === '/clients/submit') {
      response = this.clientManagementMockService.submitClientDetails(request);
    } else if (method === 'PUT' && path.startsWith('/clients') && path.endsWith('/email')) {
      const id = nthSegment(path, 2);

      response = this.clientManagementMockService.submitClientEmail(request, id);
    } else if (method === 'GET' && path.startsWith('/clients') && path.endsWith('/invite-status')) {
      const id = nthSegment(path, 2);

      response = this.clientManagementMockService.getClientInviteStatus(request, id);
    } else if (method === 'GET' && path === '/clients/invites') {
      response = this.clientManagementMockService.getClientInvites(request);
    } else if (method === 'GET' && path === '/clients/invites/metadata') {
      response = this.clientManagementMockService.getClientInvitesMetadata(request);
    } else if (method === 'PUT' && path === '/clients/invite/bulk') {
      response = this.clientManagementMockService.inviteClientsBulk(request);
    } else if (method === 'PUT' && path === '/clients/invite/all') {
      response = this.clientManagementMockService.inviteAllClients(request);
    } else if (method === 'GET' && path.startsWith('/clients/') && path.endsWith('/personal-details')) {
      const id = nthSegment(path, 2);

      if (id === '-') {
        response = this.accountMockService.getPersonalDetails(request);
      }
    } else if (method === 'PUT' && path.startsWith('/clients/') && path.endsWith('/personal-details')) {
      const id = nthSegment(path, 2);

      if (id === '-') {
        response = this.accountMockService.updatePersonalDetails(request);
      }
    } else if (method === 'GET' && path.startsWith('/clients/') && path.endsWith('/juristic-details')) {
      const id = nthSegment(path, 2);

      if (id === '-') {
        response = this.accountMockService.getJuristicDetails(request);
      }
    } else if (method === 'PUT' && path.startsWith('/clients/') && path.endsWith('/juristic-details')) {
      const id = nthSegment(path, 2);

      if (id === '-') {
        response = this.accountMockService.updateJuristicDetails(request);
      }
    } else if (method === 'GET' && path.startsWith('/clients/') && path.endsWith('/trust-details')) {
      const id = nthSegment(path, 2);

      if (id === '-') {
        response = this.accountMockService.getTrustDetails(request);
      }
    } else if (method === 'PUT' && path.startsWith('/clients/') && path.endsWith('/trust-details')) {
      const id = nthSegment(path, 2);

      if (id === '-') {
        response = this.accountMockService.updateTrustDetails(request);
      }
    } else if (method === 'GET' && path.startsWith('/clients/') && path.endsWith('/tax-details')) {
      const id = nthSegment(path, 2);

      if (id === '-') {
        response = this.accountMockService.getTaxDetails(request);
      }
    } else if (method === 'PUT' && path.startsWith('/clients/') && path.endsWith('/tax-details')) {
      const id = nthSegment(path, 2);

      if (id === '-') {
        response = this.accountMockService.updateTaxDetails(request);
      }
    } else if (method === 'GET' && path.startsWith('/clients/') && path.includes('/banking-details/')) {
      const id = nthSegment(path, 2);
      const currency = nthSegment(path, 4);
      response = this.clientManagementMockService.getClientBankingDetails(request, id, currency);
    } else if (method === 'PUT' && path.startsWith('/clients/') && path.includes('/banking-details/')) {
      const id = nthSegment(path, 2);
      const currency = nthSegment(path, 4);
      this.paymentsMockService.markDetailsDone(id, request);
      response = this.clientManagementMockService.updateClientBankingDetails(request, id, currency);
    } else if (method === 'GET' && path.startsWith('/clients/') && path.endsWith('/documents')) {
      const id = nthSegment(path, 2);
      response = this.clientManagementMockService.getClientDocuments(request, id);
    } else if (method === 'GET' && path.startsWith('/clients/') && path.endsWith('/document-types')) {
      const id = nthSegment(path, 2);
      response = this.clientManagementMockService.getClientDocuments(request, id);
    } else if (method === 'GET' && path.startsWith('/clients/') && path.endsWith('/documents/metadata')) {
      const id = nthSegment(path, 2);
      response = this.clientManagementMockService.getClientDocumentsMetadata(request, id);
    } else if (method === 'GET' && path.startsWith('/clients/') && path.endsWith('/questions/metadata')) {
      const id = nthSegment(path, 2);
      response = this.clientManagementMockService.getClientQuestionsMetadata(request, id);
    } else if (method === 'GET' && path.startsWith('/clients/') && (path.match(/\//) || []).length === 3) {
      const id = nthSegment(path, 2);
      response = this.clientManagementMockService.getClientDetails(request, id);
    } else if (method === 'PUT' && path.startsWith('/clients/') && (path.match(/\//) || []).length === 3) {
      const id = nthSegment(path, 2);
      response = this.clientManagementMockService.updateClientDetails(request, id);
    }
    // discretions
    else if (method === 'GET' && path === '/discretions/metadata') {
      response = this.discretionManagementMockService.getDiscretionsMetadata(request);
    } else if (method === 'GET' && path === '/discretions') {
      response = this.discretionManagementMockService.getDiscretions(request);
    } else if (method === 'POST' && path.startsWith('/discretions/') && path.endsWith('/submit')) {
      const id = nthSegment(path, 2);

      response = this.discretionManagementMockService.submitDiscretionClientDocuments(request, id);
    } else if (method === 'POST' && path === '/discretions/bulk') {
      response = this.discretionManagementMockService.submitBulkDiscretionDocuments(request);
    }

    // document types
    else if (method === 'GET' && path === '/document-types') {
      response = this.documentTypesMockService.getDocumentTypes(request);
    } else if (method === 'GET' && path === '/document-types/metadata') {
      response = this.documentTypesMockService.getDocumentTypesMetadata(request);
    } else if (method === 'GET' && path.startsWith('/document-types/') && path.endsWith('/documents/metadata')) {
      response = this.documentTypesMockService.getDocumentTypeDocumentsMetadata(request);
    } else if (method === 'GET' && path.startsWith('/document-types/') && path.endsWith('/clients/metadata')) {
      const id = nthSegment(path, 2);

      response = this.documentTypesMockService.getDocumentTypeClientsMetadata(request, id);
    } else if (method === 'GET' && path.startsWith('/document-types/') && path.endsWith('/clients')) {
      const id = nthSegment(path, 2);

      response = this.documentTypesMockService.getDocumentTypeClients(request, id);
    } else if (method === 'PUT' && path.startsWith('/document-types/') && path.endsWith('/sign')) {
      const id = nthSegment(path, 2);

      response = this.documentTypesMockService.signDocumentType(request, id);
    } else if (method === 'PUT' && path.startsWith('/document-types/') && path.endsWith('/mark-printed')) {
      const id = nthSegment(path, 2);

      response = this.documentTypesMockService.markDocumentTypeAsPrinted(request, id);
    } else if (method === 'POST' && (path.startsWith('/clients/') || path.startsWith('/document-types/')) && path.endsWith('/upload')) {
      const id = nthSegment(path, 2);

      response = this.documentTypesMockService.uploadDocumentType(request, id);
    } else if (method === 'GET' && path.startsWith('/document-types/') && path.endsWith('/dividend-events')) {
      response = new HttpResponse({ body: 'Test-file' });
    }

    // payments
    else if (method === 'GET' && path === '/payments') {
      response = this.paymentsMockService.getPayments(request);
    } else if (method === 'GET' && path === '/payments/confirmations') {
      response = this.paymentsMockService.getPaymentConfirmations(request);
    } else if (method === 'PUT' && path === '/payments/confirmations/submit') {
      response = this.paymentsMockService.submitPaymentConfirmations(request);
    } else if (method === 'GET' && path === '/payments/pending') {
      response = this.paymentsMockService.getPendingPayments(request);
    }

    // messages
    else if (method === 'GET' && path === '/message-threads') {
      response = this.messagesMockService.getMessageThreads(request);
    } else if (method === 'POST' && path === '/message-threads') {
      response = this.messagesMockService.createMessageThread(request);
    } else if (method === 'GET' && path === '/message-threads/latest') {
      response = this.messagesMockService.getLastMessageThread(request);
    } else if (method === 'GET' && path.startsWith('/message-threads') && path.endsWith('/messages')) {
      const id = nthSegment(path, 2);

      response = this.messagesMockService.getMessages(request, id);
    } else if (method === 'GET' && path.startsWith('/message-threads')) {
      const id = nthSegment(path, 2);

      response = this.messagesMockService.getMessageThread(request, id);
    } else if (method === 'POST' && path.startsWith('/message-threads') && path.endsWith('/read')) {
      const id = nthSegment(path, 2);

      response = this.messagesMockService.readMessageThread(request, id);
    } else if (method === 'POST' && path.startsWith('/message-threads') && path.endsWith('/add-participant')) {
      const id = nthSegment(path, 2);

      response = this.messagesMockService.addParticipant(request, id);
    } else if (method === 'POST' && path.startsWith('/message-threads')) {
      const id = nthSegment(path, 2);

      response = this.messagesMockService.sendMessage(request, id);
    } else if (method === 'GET' && path === '/message-contacts') {
      response = this.messagesMockService.getMessageContacts(request);
    }

    // questions
    else if (method === 'GET' && path === '/questions') {
      response = this.questionsMockService.getQuestions(request);
    } else if (method === 'GET' && path === '/questions/metadata') {
      response = this.questionsMockService.getQuestionsMetadata(request);
    } else if (method === 'PATCH' && path === '/questions/metadata') {
      response = this.questionsMockService.patchQuestionsMetadata(request);
    } else if (method === 'GET' && path === '/questions/clients/metadata') {
      response = this.questionsMockService.getQuestionsClientsMetadata(request);
    } else if (method === 'GET' && path === '/questions/dividend-events') {
      response = this.questionsMockService.getDividendEventReport(request);
    } else if (method === 'PUT' && path === '/questions/answers') {
      response = this.questionsMockService.setAnswers(request);
    } else if (method === 'POST' && path === '/questions/submit') {
      response = this.questionsMockService.submitAnswers(request);
    }

    //custodian
    else if (method === 'GET' && path === '/form-populations/request-template') {
      response = this.formPopulationMockService.getFormPopulationRequestTemplate(request);
    } else if (method === 'POST' && path === '/form-populations') {
      response = this.formPopulationMockService.createFormPopulations(request);
    } else if (method === 'GET' && path === '/form-populations') {
      response = this.formPopulationMockService.getFormPopulations(request);
    } else if (method === 'GET' && path === '/tax-rates/countries') {
      response = this.taxRatesMockService.getTaxRateCountries();
    } else if (method === 'GET' && path.startsWith('/tax-rates/countries/au/investor-types')) {
      response = this.taxRatesMockService.getTaxRateBeneficialOwnerTypesAu();
    } else if (method === 'GET' && path.startsWith('/tax-rates/countries/de/investor-types')) {
      response = this.taxRatesMockService.getTaxRateBeneficialOwnerTypesDe();
    } else if (method === 'GET' && path.startsWith('/tax-rates/countries/za/investor-types')) {
      response = this.taxRatesMockService.getTaxRateBeneficialOwnerTypesZa();
    } else if (method === 'POST' && path.startsWith('/tax-rates/countries/de/investor-types/pensionFund/risk-report-requests')) {
      response = this.taxRatesMockService.submitBeneficialOwnerHealthCheckRequest(request);
    } else if (method === 'GET' && path.startsWith('/tax-rates/risk-reports/beneficial-owner-health-checks')) {
      response = this.taxRatesMockService.getBeneficialOwnerHealthCheckReports(request);
    } else if (method === 'POST' && path.startsWith('/tax-rates/countries/de/risk-report-requests')) {
      response = this.taxRatesMockService.submitBeneficialOwnerHealthCheckRequest(request);
    } else if (method === 'GET' && path.startsWith('/tax-rates/risk-reports/tax-rate-table-health-checks')) {
      response = this.taxRatesMockService.getBeneficialOwnerHealthCheckReports(request);
    } else if (method === 'GET' && path === '/tax-rates/risk-reports/tax-reclaim-accrual-reports/request-template') {
      response = this.taxRatesMockService.getTaxReclaimAccrualRequestTemplate(request);
    } else if (method === 'GET' && path === '/tax-rates/risk-reports/tax-reclaim-accrual-reports') {
      response = this.taxRatesMockService.getTaxReclaimAccrualReports(request);
    } else if (method === 'POST' && path === '/tax-rates/risk-reports/tax-reclaim-accrual-reports') {
      response = this.taxRatesMockService.submitTaxReclaimAccrualReportRequest(request);
    }
    // not implemented
    else {
      logError(`request ${request.method} ${request.url} cannot be fulfilled`);
      response = new HttpResponse({ status: 500 });
    }

    log('sending response', response.body);
    logGroupEnd();

    return of(response).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.status !== 200) {
          throw new HttpErrorResponse({ status: event.status });
        }
        return event;
      }),
      delay(MOCK_RESPONSE_DELAY_MS)
    );
  }
}
