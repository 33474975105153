<span *ngIf="disabled; else enabledState" class="font-size-reduced navigation-item-container disabled">
  {{ menuItem.name | translate }}
</span>

<ng-template #enabledState>
  <span>
    <a
      *ngIf="menuItem.type === MenuItemType.RouterLink"
      class="font-size-reduced"
      [ngClass]="{
        'navigation-item-container': '(isCurrentUserCustodian$ | async) === false',
        'custodian-nav-item-container': '(isCurrentUserCustodian$ | async) === true'
      }"
      [ngStyle]="{ color: isNewNavColor ? 'white' : 'black' }"
      [routerLink]="menuItem.link"
      routerLinkActive="active"
      (click)="menuItem.subMenuItems ? null : onmenuItemClick(menuItem)"
    >
      {{ menuItem.name | translate }}

      <ng-container [ngTemplateOutlet]="badgeTemplate"></ng-container>
    </a>

    <a
      *ngIf="menuItem.type === MenuItemType.Href"
      class="font-size-reduced"
      [ngClass]="{
        'navigation-item-container': (isCurrentUserInstitutions$ | async) === true,
        'custodian-nav-item-container': (isCurrentUserCustodian$ | async) === true,
        'private-wealth-nav-item-container': (isCurrentUserPrivateWealth$ | async) === true
      }"
      [ngStyle]="{ color: isNewNavColor ? 'white' : 'black' }"
      [href]="menuItem.link"
      [target]="menuItem.openInNewTab ? '_blank' : '_self'"
      (click)="onmenuItemClick(menuItem)"
    >
      {{ menuItem.name | translate }}

      <ng-container [ngTemplateOutlet]="badgeTemplate"></ng-container>
    </a>
    <div class="sub-menu-items" *ngIf="menuItem.subMenuItems">
      <a *ngFor="let subMenuItem of menuItem.subMenuItems" [routerLink]="subMenuItem.link" routerLinkActive="active">
        {{ subMenuItem.name | translate }}
      </a>
    </div>
  </span>
</ng-template>

<ng-template #badgeTemplate>
  <span *ngIf="menuItem.badge$" class="badge" [ngClass]="menuItem.badge$ | async"></span>
</ng-template>
