import { HttpRequest, HttpResponse } from '@angular/common/http';
import {
  AccountCompleteness,
  AccountSetUpStep,
  AccountSetUpStepStatus,
  AccountSetUpStepType,
  AccountStatus,
  AuthorizedSignatories,
  BankingDetails,
  CompanyDetails,
  CustodianDetails,
  DiscretionDetails,
  JuristicDetails,
  PersonalDetails,
  SignatoryDetailsStatus,
  TaxDetails,
  TrustDetails,
} from '@wtax/data-angular';
import { parseToken, randomBool } from '../functions';
import { emptyJuristicDetails, emptyPersonalDetails, emptyTaxDetails, emptyTrustDetails } from '../stubs';

export class AccountMockService {
  public getStatus(request: HttpRequest<any>): HttpResponse<AccountStatus> {
    const token = parseToken(request);
    let body: AccountStatus;

    if (token.startsWith('WM')) {
      body = this.wmStatus();
    } else if (token.startsWith('FM')) {
      body = this.fmStatus();
    } else if (token.startsWith('BO')) {
      body = this.boStatus();
    } else if (token.startsWith('CUSTODIAN')) {
      body = this.custodianStatus();
    } else if (token.startsWith('INVESTOR')) {
      body = this.investorStatus();
    }

    return new HttpResponse({ status: 200, body });
  }

  public wmStatus(): AccountStatus {
    const steps: AccountSetUpStep[] = [
      {
        type: AccountSetUpStepType.COMPANY_DETAILS,
        status: this.companyDetailsStatus,
        is_optional: false,
      },
      {
        type: AccountSetUpStepType.CUSTODIAN_DETAILS,
        status: this.custodianDetailsStatus,
        is_optional: false,
      },
      {
        type: AccountSetUpStepType.DISCRETION_DETAILS,
        status: this.discretionDetailsStatus,
        is_optional: false,
      },
    ];

    const completed = steps.every((step) => step.status === AccountSetUpStepStatus.COMPLETED);

    return {
      completeness: completed ? AccountCompleteness.COMPLETED : AccountCompleteness.INCOMPLETE,
      steps,
    };
  }

  public fmStatus(): AccountStatus {
    const steps: AccountSetUpStep[] = [
      {
        type: AccountSetUpStepType.COMPANY_DETAILS,
        status: this.companyDetailsStatus,
        is_optional: false,
      },
      {
        type: AccountSetUpStepType.CUSTODIAN_DETAILS,
        status: this.custodianDetailsStatus,
        is_optional: false,
      },
    ];

    const completed = steps.every((step) => step.status === AccountSetUpStepStatus.COMPLETED);

    return {
      completeness: completed ? AccountCompleteness.COMPLETED : AccountCompleteness.INCOMPLETE,
      steps,
    };
  }

  public custodianStatus(): AccountStatus {
    const steps: AccountSetUpStep[] = [];

    return {
      completeness: AccountCompleteness.COMPLETED,
      steps,
    };
  }

  public boStatus(): AccountStatus {
    const steps: AccountSetUpStep[] = [
      {
        type: AccountSetUpStepType.PERSONAL_DETAILS,
        status: this.personalDetailsStatus,
        is_optional: false,
      },
      {
        type: AccountSetUpStepType.JURISTIC_DETAILS,
        status: this.juristicDetailsStatus,
        is_optional: false,
      },
      {
        type: AccountSetUpStepType.TRUST_DETAILS,
        status: this.trustDetailsStatus,
        is_optional: false,
      },
      {
        type: AccountSetUpStepType.TAX_DETAILS,
        status: this.taxDetailsStatus,
        is_optional: false,
      },
    ];

    const completed = steps.every((step) => step.status === AccountSetUpStepStatus.COMPLETED);

    return {
      completeness: completed ? AccountCompleteness.COMPLETED : AccountCompleteness.INCOMPLETE,
      steps,
    };
  }

  public investorStatus(): AccountStatus {
    const steps: AccountSetUpStep[] = [
      {
        type: AccountSetUpStepType.JURISTIC_DETAILS,
        status: this.juristicDetailsStatus,
        is_optional: false,
      },
      {
        type: AccountSetUpStepType.TRUST_DETAILS,
        status: this.trustDetailsStatus,
        is_optional: false,
      },
      {
        type: AccountSetUpStepType.TAX_DETAILS,
        status: this.taxDetailsStatus,
        is_optional: false,
      },
    ];

    const completed = steps.every((step) => step.status === AccountSetUpStepStatus.COMPLETED);

    return {
      completeness: completed ? AccountCompleteness.COMPLETED : AccountCompleteness.INCOMPLETE,
      steps,
    };
  }

  // -----

  private companyDetailsStatus = AccountSetUpStepStatus.NOT_STARTED;
  private companyDetails: CompanyDetails = {
    name: 'Wealth Management Ltd',
    registered_address: null,
    has_same_postal_address: null,
    postal_address: null,
    has_previous_engagements: null,
    previous_engagements: null,
  };

  public getCompanyDetails(_: HttpRequest<any>): HttpResponse<CompanyDetails> {
    return new HttpResponse({ status: 200, body: this.companyDetails });
  }

  public updateCompanyDetails(request: HttpRequest<CompanyDetails>): HttpResponse<CompanyDetails> {
    this.companyDetails = request.body;
    this.companyDetailsStatus = this.validateCompanyDetails();
    return new HttpResponse({ status: 200, body: this.companyDetails });
  }

  private validateCompanyDetails(): AccountSetUpStepStatus {
    return AccountSetUpStepStatus.COMPLETED;
  }

  // -----

  private custodianDetailsStatus = AccountSetUpStepStatus.COMPLETED;
  private custodianDetails: CustodianDetails = {
    active_custodians: null,
    has_changed_custodians: null,
    past_custodians: null,
    confirmed: false,
  };

  public getCustodianDetails(_: HttpRequest<any>): HttpResponse<CustodianDetails> {
    return new HttpResponse({ status: 200, body: this.custodianDetails });
  }

  public updateCustodianDetails(request: HttpRequest<CustodianDetails>): HttpResponse<CustodianDetails> {
    this.custodianDetails = request.body;
    this.custodianDetailsStatus = this.validateCustodianDetails(this.custodianDetails.confirmed);
    return new HttpResponse({ status: 200, body: this.custodianDetails });
  }

  private validateCustodianDetails(confirmed: boolean): AccountSetUpStepStatus {
    return confirmed ? AccountSetUpStepStatus.COMPLETED : AccountSetUpStepStatus.INCOMPLETE;
  }

  // -----

  private discretionDetailsStatus = AccountSetUpStepStatus.NOT_STARTED;
  private discretionDetails: DiscretionDetails = {
    authorization: null,
    authorized_signatories: {
      attachment_ids: [],
      id_documents_required_for_signatories: randomBool(),
    },
  };

  public getDiscretionDetails(_: HttpRequest<any>): HttpResponse<DiscretionDetails> {
    return new HttpResponse({ status: 200, body: this.discretionDetails });
  }

  public updateDiscretionDetails(request: HttpRequest<DiscretionDetails>): HttpResponse<DiscretionDetails> {
    this.discretionDetails = request.body;
    this.discretionDetailsStatus = this.validateDiscretionDetails();
    return new HttpResponse({ status: 200, body: this.discretionDetails });
  }

  private validateDiscretionDetails(): AccountSetUpStepStatus {
    return AccountSetUpStepStatus.COMPLETED;
  }

  // -----
  private bankingDetails: BankingDetails = {
    account: null,
    needs_more_details: null,
    beneficiary: null,
    intermediary: null,
  };

  public getBankingDetails(_: HttpRequest<any>): HttpResponse<BankingDetails> {
    return new HttpResponse({ status: 200, body: this.bankingDetails });
  }

  public updateBankingDetails(request: HttpRequest<BankingDetails>): HttpResponse<BankingDetails> {
    this.bankingDetails = request.body;
    return new HttpResponse({ status: 200, body: this.bankingDetails });
  }

  // -----

  private personalDetailsStatus = AccountSetUpStepStatus.NOT_STARTED;
  private personalDetails: PersonalDetails = emptyPersonalDetails();

  public getPersonalDetails(_: HttpRequest<any>): HttpResponse<PersonalDetails> {
    return new HttpResponse({ status: 200, body: this.personalDetails });
  }

  public updatePersonalDetails(request: HttpRequest<PersonalDetails>): HttpResponse<PersonalDetails> {
    this.personalDetails = request.body;
    this.personalDetailsStatus = this.validatePersonalDetails();
    return new HttpResponse({ status: 200, body: this.personalDetails });
  }

  private validatePersonalDetails(): AccountSetUpStepStatus {
    return AccountSetUpStepStatus.COMPLETED;
  }

  // -----

  private juristicDetailsStatus = AccountSetUpStepStatus.NOT_STARTED;
  private juristicDetails: JuristicDetails = emptyJuristicDetails();

  public getJuristicDetails(_: HttpRequest<any>): HttpResponse<JuristicDetails> {
    return new HttpResponse({ status: 200, body: this.juristicDetails });
  }

  public updateJuristicDetails(request: HttpRequest<JuristicDetails>): HttpResponse<JuristicDetails> {
    this.juristicDetails = request.body;
    this.juristicDetailsStatus = this.validateJuristicDetails();
    return new HttpResponse({ status: 200, body: this.juristicDetails });
  }

  private validateJuristicDetails(): AccountSetUpStepStatus {
    return AccountSetUpStepStatus.COMPLETED;
  }

  // -----

  private trustDetailsStatus = AccountSetUpStepStatus.NOT_STARTED;
  private trustDetails: TrustDetails = emptyTrustDetails();

  public getTrustDetails(_: HttpRequest<any>): HttpResponse<TrustDetails> {
    return new HttpResponse({ status: 200, body: this.trustDetails });
  }

  public updateTrustDetails(request: HttpRequest<TrustDetails>): HttpResponse<TrustDetails> {
    this.trustDetails = request.body;
    this.trustDetailsStatus = this.validateTrustDetails();
    return new HttpResponse({ status: 200, body: this.trustDetails });
  }

  private validateTrustDetails(): AccountSetUpStepStatus {
    return AccountSetUpStepStatus.COMPLETED;
  }

  // -----

  private taxDetailsStatus = AccountSetUpStepStatus.NOT_STARTED;
  private taxDetails: TaxDetails = emptyTaxDetails();

  public getTaxDetails(_: HttpRequest<any>): HttpResponse<TaxDetails> {
    return new HttpResponse({ status: 200, body: this.taxDetails });
  }

  public updateTaxDetails(request: HttpRequest<TaxDetails>): HttpResponse<TaxDetails> {
    this.taxDetails = request.body;
    this.taxDetailsStatus = this.validateTaxDetails();
    return new HttpResponse({ status: 200, body: this.taxDetails });
  }

  private validateTaxDetails(): AccountSetUpStepStatus {
    return AccountSetUpStepStatus.COMPLETED;
  }

  public getAuthorizedSignatories(_: HttpRequest<any>): HttpResponse<AuthorizedSignatories> {
    return new HttpResponse({ status: 200, body: this.discretionDetails.authorized_signatories });
  }

  // ----
  private _signatoryDetailsStatus: SignatoryDetailsStatus = SignatoryDetailsStatus.NOT_UPLOADED;

  public signatoryDetailsStatus(): SignatoryDetailsStatus {
    return this._signatoryDetailsStatus;
  }
  public updateAuthorizedSignatories(_: HttpRequest<any>): HttpResponse<void> {
    this._signatoryDetailsStatus = SignatoryDetailsStatus.UPLOADED;
    return new HttpResponse({ status: 200 });
  }
}
